import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Loader from "~/components/UI/Loader";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  getDailyTransactionStats,
  adminGetTransactionStats,
  getWeeklyTransactionStats,
  getMonthlyTransactionStats,
} from "~/queries/transactionQueries";
import { getCommaSepertatedNumber } from "~/utils/formatAmount";

export const Chart = () => {
  const [currency, setCurrency] = useState<string>("NGN");
  const [timeFrame, setTimeFrame] = useState<string>("DAILY");

  const getStatsQueryFn = () => {
    if (timeFrame === "DAILY") {
      return getDailyTransactionStats;
    } else if (timeFrame === "WEEKLY") {
      return getWeeklyTransactionStats;
    } else {
      return getMonthlyTransactionStats;
    }
  };

  const { data: dailyTransactionStat, isLoading: statsLoading } = useQuery({
    queryKey: [timeFrame, "stats", currency],
    queryFn: async () => {
      try {
        const { data } = await getStatsQueryFn()({
          "filter[currency]": currency,
        });
        return data;
      } catch (err: any) {
        toast.error(err.message);
        throw err;
      }
    },
  });

  const { data: adminTransactionStats, isLoading: transactionStatsLoading } =
    useQuery({
      queryKey: ["transaction", "stats", currency],
      queryFn: async () => {
        try {
          const { data } = await adminGetTransactionStats({
            "filter[currency]": currency,
          });
          return data;
        } catch (err: any) {
          toast.error(err.message);
          throw err;
        }
      },
    });

  const getStat = (key: string) => {
    if (!adminTransactionStats) return "0";
    const [stat] = adminTransactionStats.filter((st: any) => st.value === key);
    return stat?.amount || "0";
  };

  const limitedData =
    timeFrame === "DAILY"
      ? dailyTransactionStat?.slice(0, 15) // Last 15 data points for daily
      : timeFrame === "WEEKLY"
      ? dailyTransactionStat?.slice(0, 8) // Last 8 data points for weekly
      : dailyTransactionStat?.slice(0, 12); // Last 12 data points for monthly

  interface ChartDataItem {
    count: number;
    value?: string;
  }

  const chartData: ChartDataItem[] = limitedData || [];
  // const countArray = chartData.map((item) => item.count);
  // const dateArray = chartData.map((item) => item.value);

  const getCurrencySymbol = (currency: string) => {
    return currency === "USD" ? "$" : "₦";
  };

  interface FormattedDataPoint {
    x: any;
    y: any;
  }

  const formattedData: FormattedDataPoint[] = chartData.map((item) => ({
    x: item.value,
    y: item.count,
  }));

  const series: ApexOptions["series"] = [
    {
      data: formattedData,
    },
  ];

  const options: ApexOptions = {
    chart: {
      toolbar: { show: false },
      type: "area",
      background: "#fff",
    },
    stroke: {
      show: false,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: ["#525866"],
          fontSize: "12px",
          fontFamily: "Inter, Helvetica, Arial, sans-serif",
          fontWeight: 400,
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#525866"],
          fontSize: "12px",
          fontFamily: "Inter, Helvetica, Arial, sans-serif",
          fontWeight: 400,
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  return (
    <div className="bg-white border p-4 rounded-xl border-[#E2E4E9] shadow-sm h-full flex flex-col w-full">
      <div className="flex flex-row">
        <header className="w-full flex flex-col">
          <span className="font-medium text-sm">Total Transaction Amount</span>
          <h1 className="font-semibold text-2xl mt-1">
            {getCurrencySymbol(currency)}{" "}
            {getCommaSepertatedNumber(getStat("success"))}
          </h1>
        </header>

        <div className="bg-transparent border w-[12%] p-2 rounded-xl flex items-center justify-around">
          <select
            className="outline-none text-sm shrink-0 border-l border-[#E2E4E9]"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="NGN">NGN</option>
            <option value="USD">USD</option>
          </select>
        </div>

        <div className="bg-transparent border w-[12%] p-2 rounded-xl flex items-center justify-around ml-5">
          <select
            className="outline-none text-sm shrink-0 border-l border-[#E2E4E9]"
            value={timeFrame}
            onChange={(e) => setTimeFrame(e.target.value)}
          >
            <option value="DAILY">Daily</option>
            <option value="WEEKLY">Weekly</option>
            <option value="MONTHLY">Monthly</option>
          </select>
        </div>
      </div>

      {statsLoading || transactionStatsLoading ? (
        <Loader big />
      ) : (
        adminTransactionStats &&
        limitedData && (
          <>
            <div className="mt-5" style={{ width: "100%", height: "225px" }}>
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={212}
              />
            </div>
          </>
        )
      )}
      {(!limitedData || limitedData.length === 0) && (
        <p>No data available to display</p>
      )}
    </div>
  );
};
