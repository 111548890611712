import { AuthLayout } from "../layouts";
import {
  LoginPage,
  SignupPage,
  VerifyAccountPage,
  ProfileSetupPage,
  OnboardingPage,
  AcceptInvitePage,
  Complete,
  WorkspacePage,
  ForgotPasswordPage,
  ForgotVerifyPage,
  ResetPasswordPage,
  CreateProjectRequestsPage,
  RequestThanks,
  CloseIntegrationPage,
} from "../pages";
import { PublicPlansPage } from "~/pages/organization/subscription-pages/Plans";
import { ErrorPage } from "../pages/error/index";

export const AuthRoutes = [
  {
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <LoginPage />, errorElement: <ErrorPage /> },
      { path: "/choose", element: <WorkspacePage />, errorElement: "" },
      {
        path: "/close/:integration",
        element: <CloseIntegrationPage />,
        errorElement: "",
      },
      {
        path: "/requests/create",
        element: <CreateProjectRequestsPage />,
        errorElement: "",
      },
      {
        path: "/requests/thanks",
        element: <RequestThanks />,
        errorElement: "",
      },
      // { path: "/signup", element: <SignupPage />, errorElement: "" },
      {
        path: "/signup",
        element: <SignupPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/oauth/complete/:provider",
        element: <Complete />,
        errorElement: "",
      },
      {
        path: "/signup/verify",
        element: <VerifyAccountPage />,
        errorElement: "",
      },
      {
        path: "/signup/profile",
        element: <ProfileSetupPage />,
        errorElement: "",
      },
      {
        path: "/signup/onboarding",
        element: <OnboardingPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/signup/accept",
        element: <AcceptInvitePage />,
        errorElement: "",
      },
      { path: "/signup", element: <SignupPage />, errorElement: "" },
      { path: "/forgot", element: <ForgotPasswordPage />, errorElement: "" },
      {
        path: "/forgot/verify",
        element: <ForgotVerifyPage />,
        errorElement: "",
      },
      {
        path: "/forgot/reset",
        element: <ResetPasswordPage />,
        errorElement: "",
      },
      {
        path: "/plans",
        element: <PublicPlansPage />,
        errorElement: "",
      },
    ],
  },
];
